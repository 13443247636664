@keyframes placeholder-shimmer {
    0% {
        background-position: -25rem 0;
    }

    100% {
        background-position: 25rem 0;
    }
}

.osf-img-tagger-widget {
    aspect-ratio: 1/1;

    &.loaded {
        aspect-ratio: initial;
    }

    &:not(.loaded) {
        background: var(--background-color);
        background: linear-gradient(90deg, var(--background-color) 8%, var(--background-gradient-color) 18%, var(--background-color) 33%);
        background-size: 50rem 6.25rem;
        animation-name: placeholder-shimmer;
        animation-duration: 1s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        animation-fill-mode: forwards;
        pointer-events: none;
        color: transparent;
        margin-bottom: 1.6rem;

        * {
            opacity: 0;
        }
    }
}

$md: "768px";

@media screen and (min-width: $md) {
    .osf-img-tagger-widget {
        aspect-ratio: 2/1;
    }
}

.fade-out {
    transition-delay: 0.5;
    display: none;
}
